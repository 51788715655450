import { useEffect, useState } from "react";
import { IoChevronDown } from "react-icons/io5";

const InputDropdown = ({
  label,
  placeholder,
  options,
  onSelect,
  showError,
  resetState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    console.log("i am being called in the input dropdown");
    if (resetState) {
      setSelectedOption("");
    }
  }, [resetState]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) onSelect(option);
  };

  return (
    <div className="w-full max-w-lg mt-4">
      <label className="block text-white text-[18px] font-semibold mb-2 ">
        {label}
      </label>
      <div className="relative">
        <div
          className={`w-full border-2 border-[#C7C7C7] text-white bg-black px-4 py-2 rounded-lg flex justify-between items-center cursor-pointer h-14 ${
            showError ? "border-red-500" : "border-[#C7C7C7]"
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span
            className={`regular  ${
              selectedOption
                ? ""
                : showError
                ? "text-red-500"
                : "text-white opacity-50"
            }`}
          >
            {selectedOption || placeholder}
          </span>
          <IoChevronDown className="w-5 h-5 text-gray-400" />
        </div>
        {isOpen && (
          <ul className="absolute left-0 mt-1 w-full bg-black border-2 border-[#C7C7C7] rounded-lg shadow-lg max-h-60 overflow-auto z-10">
            {options.map((option, index) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-gray-800 cursor-pointer text-white"
                onClick={() => handleSelect(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default InputDropdown;
